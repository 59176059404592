<template>
     <section class="community-dynamic padding_10_15">
          <r-e-table
                  class="bg-white"
                  :data="tableData"
                  :columns="dynamicTableColumn"
                  tooltip-effect="light"
                  :height="515"
                  :show-pagination="false"
          />
<!--          <r-e-table ref="communityTableRef" :dataRequest="getCommunityApi" :columns="dynamicTableColumn"
                  :query="formSearch" :height="465"/>-->
     </section>
</template>

<script>
     import {dynamicTableColumn} from "@/views/home/data";
     import {getCommunityListApi} from "@/api/home";

     export default {
          name: "community-dynamic",
          data() {
               return {
                    tableData: [
                         /*{
                             a1: '金色地中海2期',
                             a2: '2',
                             a3: '2',
                             a4: '1',
                             a5: '1',
                             a6: '0',
                             a7: '2',
                         },
                         {
                             a1: '富航217青年公寓',
                             a2: '201',
                             a3: '185',
                             a4: '55',
                             a5: '40',
                             a6: '10',
                             a7: '185',
                         }*/
                    ],
                    dynamicTableColumn,
                    formSearch:{}
               };
          },
          props: {
               collectionData: {
                    type: Object,
                    default: () => ({})
               }
          },
          methods: {
               getCommunityApi(params) {
                    return getCommunityListApi();
               },
               handleSearch() {
                    this.$refs["communityTableRef"].getTableData();
               },
          },
          watch:{
               collectionData(value){
                    // this.handleSearch();
               }
          }
     };
</script>

<style lang="scss" scoped>

</style>
