<template>
    <section class="retire-situation flex flex-warp justify-between">
        <div class="retire-item bg-white" v-for="item in retireOptions" :key="item.name">
            <div :style="{color:item.color}">
                <div class="label" style="padding-bottom: 0; font-size: 1.5rem;">{{ item.value }} <span>{{ item.unit }}</span></div>
                <div class="label">{{ item.label }}</div>
            </div>
        </div>
    </section>
</template>

<script>
    import {retireOptions} from "@/views/home/data";
    // import {throwLease} from "@/api/home";

    export default {
        name: "retire-situation",
        data() {
            return {
                retireOptions
            };
        },
        methods: {
            async throwLease(paramData){
                /*let res = await throwLease(paramData);
                let {info: {TZCount, TZPercent, DQCount, ZTCount}} = res;
                let {retireOptions} = this;
                for (let item of retireOptions) {
                    if (item.label === "退租数量") {
                        item.value = TZCount;
                    }
                    if (item.label === "退租率") {
                        item.value = TZPercent;
                    }
                    if (item.label === "到期退租") {
                        item.value = DQCount;
                    }
                    if (item.label === "中途退租") {
                        item.value = ZTCount;
                    }
                }
                this.retireOptions = retireOptions;*/
            }
        },
        mounted() {
            this.throwLease();
        }
    };
</script>

<style lang="scss" scoped>
    .retire-situation {
        width: 100%;
        height: 100%;
        padding-left: 10px;

        .retire-item {
            width: calc(50% - 10px);
            height: calc(50% - 10px);
            color: #333;
            font-weight: 600;
            border-radius: 20px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &:nth-of-type(1),
            &:nth-of-type(2) {
                margin-bottom: 20px;
            }

            .label {
                font-size: rem(16px);
                padding: VH(15px) VW(15px);
                text-align: center;
            }

            .value {
                /*position: absolute;
                bottom: VH(15px);
                right: VW(30px);*/
                font-size: rem(19px);

                span {
                    font-size: rem(16px);
                }
            }
        }
    }
</style>
