<template>
    <section class="overview padding_10_15 flex justify-between flex-warp">
        <div v-for="item in overviewOptions" :key="item.name" :class="['overview-item', item.name]">
            <div class="title">{{ item.label }}</div>
            <div class="content" :class="item.className">
                <component :is="item.name" :collectionData="collectionData"/>
            </div>
        </div>
    </section>
</template>

<script>
    import {overviewOptions} from "@/views/home/data";
    import {operationSituation, receiptSituation, retireSituation, communityDynamic} from "@/views/home/components/part";

    export default {
        name: "overview",
        props: {
            collectionData: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                overviewOptions
            };
        },
        components: {operationSituation, receiptSituation, retireSituation, communityDynamic},
        methods: {}
    };
</script>

<style lang="scss" scoped>
    .overview {
        .title {
            height: VH(55px);
            line-height: VH(55px);
            color: #666;
            padding-left: VW(10px);
            position: relative;

            &::before {
                width: 5px;
                height: 40%;
                background-color: #5C84FB;
                content: '';
                position: absolute;
                left: 0;
                top: 30%;
            }
        }


        .overview-item {
            .content {
                border-radius: 20px;
            }

            &.operation-situation {
                //width: VW(1300px);
                width: 77.7%;
            }

            &.retire-situation {
                //width: VW(365px);
                width: 21.5%;
            }

            &.operation-situation, &.retire-situation {
                .content {
                    height: VH(300px);
                }
            }

            &.receipt-situation {
                //width: VW(565px);
                width: 38.5%;
            }

            &.community-dynamic {
                //width: VW(1100px);
                width: 60.7%;
            }

            &.receipt-situation, &.community-dynamic {
                margin-top: VH(10px);

                .content {
                    height: VH(540px);
                }
            }
        }
    }
</style>
