import { timeFormat, numberFormat } from "@custom/index";
import { conversionAmount } from "@/utils/hooks";

// 首页tabs
export const homeTabOptions = [
    {
        label: "概况",
        name: "overview",
        value: 0
    },
    {
        label: "预约",
        name: "appointment",
        value: 0
    },
    {
        label: "退房",
        name: "checkOut",
        value: 0
    },
    {
        label: "换房",
        name: "exchangeHouses",
        value: 0
    },
    {
        label: "投诉",
        name: "complaint",
        value: 0
    },
    {
        label: "维修",
        name: "repair",
        value: 0
    }
];

// 首页搜索条件
export const timeButtons = [
    {
        label: "今日",
        name: "day"
    },
    {
        label: "本月",
        name: "month"
    }
];

// 概况tabs - 4个部分
export const overviewOptions = [
    {
        label: "运营情况",
        name: "operation-situation",
        className: "bg-white"
    },
    {
        label: "上月退租情况",
        name: "retire-situation",
    },
    {
        label: "收款情况",
        name: "receipt-situation",
        className: "bg-white"
    },
    {
        label: "小区动态",
        name: "community-dynamic",
        className: "bg-white"
    }
];

// 概况tabs - 运营情况 - 统计
export const operationOptions = [
    {
        src: require("@/assets/images/home/total.png"),
        name: "total",
        color: "#1221D6",
        label: "合计",
        desc: "合计房数",
        number: 0
    },
    {
        src: require("@/assets/images/home/known.png"),
        name: "known",
        color: "#F85851",
        label: "已占有",
        desc: "已租房数",
        number: 0
    }
];

// 概况tabs - 运营情况 - echarts
export const operationECharts = {
    tooltip: {
        trigger: "item",
        formatter: function (params) {
            return `${params.marker}${params.data.name}<span style="padding: 0 5px"></span> ${params.data.number}`;
        }
    },
    color: ["#FEE5E4", "#F85951"],
    series: [
        {
            type: "pie",
            radius: ["50%", "70%"],
            label: {
                show: false,
            },
            data: []
        }
    ]
};

// 概况tabs - 运营情况 - slider
export const operationSlider = [
    {
        label: "投诉量",
        name: "a1",
        value: 0,
        rate: 0
    },
    {
        label: "报修量",
        name: "a2",
        value: 0,
        rate: 0
    },
    {
        label: "已维修量",
        name: "a3",
        value: 0,
        rate: 0
    },
    {
        label: "新签约",
        name: "a4",
        value: 0,
        rate: 0
    },
];

// 概况tabs - 上月退租情况
export const retireOptions = [
    {
        label: "退租数量",
        name: "a1",
        value: 0,
        color: "rgb(255,79,1)",
    },
    {
        label: "退租率",
        name: "a2",
        value: 0,
        unit: "%",
        color: "rgb(251,56,56)",
    },
    {
        label: "到期退租",
        name: "a3",
        value: 0,
        color: "rgb(24,94,198)",
    },
    {
        label: "中途退租",
        name: "a4",
        value: 0,
        color: "rgb(51,51,51)",
    },
];

// 概况tabs - 收款情况
export const receiptOptions = [
    {
        label: "应收款",
        name: "a1",
        value: 0,
        color: '#F85851',
        icon: require("@/assets/images/home/need.png")
    },
    {
        label: "已收款",
        name: "a2",
        value: 0,
        color: 'aqua',
        icon: require("@/assets/images/home/already.png")
    },
    {
        label: "待收款",
        name: "a3",
        value: 0,
        color: '#5C84FB',
        icon: require("@/assets/images/home/not.png")
    },
];

// 概况tabs - 收款情况
export const receiptEChartsOptions = {
    tooltip: {
        trigger: 'item',
        formatter: function (params) {
            return `${params.marker}${params.data.name}<span style="padding: 0 5px"></span> ${params.value}%`;
        }
    },
    legend: {
        bottom: 0,
        left: 'center'
    },
    color: [
        //'#FB5951',
        '#31AB3F',
        '#FB9851'],
    series: [
        {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '20',
                    fontWeight: 'bold',
                    formatter: '{d}%\n{b}'
                }
            },
            labelLine: {
                show: false
            },
            data: [
                { value: 0, name: '应收款' },
                { value: 0, name: '已收款' },
                { value: 0, name: '待收款' },
            ]
        }
    ]
}

// 概况tabs - 收款情况 - 详情
export const receiptDetailOptions = [
    {
        label: "定金",
        name: "a1",
        value: 0,
    },
    {
        label: "电费",
        name: "a2",
        value: 0,
    },
    {
        label: "水费",
        name: "a3",
        value: 0,
    },
    {
        label: "租金",
        name: "a4",
        value: 0,
    },
    {
        label: "押金",
        name: "a5",
        value: 0,
    },
    {
        label: "服务费",
        name: "a6",
        value: 0,
    }
]

// 概况tabs - 小区动态
export const dynamicTableColumn = [
    {
        prop: "name",
        label: "小区名称",
    },
    {
        prop: "apartmentsCount",
        label: "总房数",
    },
    {
        prop: "rentingCount",
        label: "已租房数"
    },
    {
        prop: "vacantCount",
        label: "空置房数"
    },
    /*{
        prop: "maintain",
        label: "报修量",
    },
    {
        prop: "maintainover",
        label: "已维修量"
    },
    {
        prop: "complaintCount",
        label: "投诉量",
    },
    {
        prop: "newcon",
        label: "新签约",
    },
    {
        prop: "rentRate",
        label: "出租率(%)",
    },*/
];

// 首页tabs 退房列表
export const tableCheckOutColumn = [
    {
        label: "序号",
        type: "index",
        width: 180
    },
    {
        prop: "timestamp",
        label: "申请时间",
        width: 200,
        render: (h, {row}) => {
            const timeStamp = !row.timestamp ? "" : timeFormat(new Date(row.timestamp), 'yyyy-MM-dd HH:mm:ss');
            return h("span", timeStamp);
        }
    },
    {
        prop: "title",
        label: "内容",
        showOverflowTooltip: true
    },
];

// 首页tabs 投诉列表
export const tableComplaintColumn = [
    {
        prop: "feedSn",
        label: "投诉编号",
        width: 150,
    },
    {
        prop: "content",
        label: "投诉内容",
        showOverflowTooltip: true,
    },
    {
        prop: "feedType",
        label: "投诉类型",
        width: 100,
    },
    {
        prop: "leasorName",
        label: "投诉人",
        width: 100,
    },
    {
        prop: "",
        label: "投诉时间",
        render: (h, { row }) => {
            return timeFormat(new Date(row.feedDate));
        },
        width: 150,
    },
    {
        prop: "state",
        label: "跟进状态",
        width: 100,
    },
    {
        prop: "feedLeasor",
        label: "处理人",
        width: 100,
    },
    {
        prop: "feedContent",
        label: "处理说明",
        showOverflowTooltip: true,
    },
    {
        prop: "authFeedContent",
        label: "驳回说明",
        showOverflowTooltip: true,
    },
];

// 首页tabs 维修列表
export const tableRepairColumn = [
    {
        prop: "repairSn",
        label: "维修编号",
    },
    {
        prop: "repairContent",
        label: "维修内容"
    },
    {
        prop: "repairType",
        label: "类型"
    },
    {
        prop: "repairPeopleName",
        label: "报修人"
    },
    {
        prop: "repairAddress",
        label: "房间"
    },
    {
        prop: "date",
        label: "维修时间",
        render: (h, { row }) => {
            return timeFormat(new Date(row.date));
        }
    },
    {
        prop: "state",
        label: "跟进状态"
    },
];
