<template>
    <section class="repair padding_10_15">
        <div class="top-bar bg-white">
            <el-input v-model="formSearch.keyword" size="small" placeholder="请输入维修编号" style="margin: 0 9px; width: 250px" />
            <el-input v-model="formSearch.keyword" size="small" placeholder="请输入报修人" style="margin: 0 9px; width: 250px" />
            <el-date-picker v-model="formSearch.start" type="date" size="small" value-format="yyyy-MM-dd" format="yyyy-MM-dd" placeholder="选择报修日期" />
            <el-select v-model="value" placeholder="请选择类型" size="small" style="margin:0px 10px">
                <el-option v-for="item in housingEstateList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <el-select v-model="value" placeholder="请选择跟进状态" size="small" style="margin-right: 10px">
                <el-option v-for="item in housingEstateList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button  @click="handleReset" size="small">重置</el-button>
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getRepairTableData" :columns="tableColumn" :query="formSearch" tooltip-effect="light" :height="800">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn"  v-if="row.stateCode === 0"  @click="handle(row)">处理</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <r-e-dialog title="维修处理" :visible.sync="dialogVisible" show-footer top="5vh" width="600px"
                    @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
            <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
                <div class="flex">
                    <el-form-item label="金额" prop="amount">
                        <el-input-number v-model="formPublish.amount" :precision="2" controls-position="right"
                                         style="width: 350px;" placeholder="请输入内容"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="处理时间" prop="commentdate">
                        <el-date-picker v-model="formPublish.commentdate" style="width: 350px;" type="date"
                                        format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="处理内容" prop="feedContent">
                        <el-input type="textarea" :rows="2" v-model="formPublish.comment" style="width: 350px;"
                                  placeholder=""/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="照片附件" class="samplePhotos">
                        <upload-pictureCard :uuidList="thumbnailFinish" :limit="5" @on-success="handleSuccess" @on-remove="handleRemove"/>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>
    </section>
</template>

<script>
    import { tableRepairColumn } from "@/views/home/data";
    // import { getRepairList, repairPubRepair} from "@/api/home";
    import uploadPictureCard from "@/components/Upload/upload-picture-card";
    import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";
    export default {
        name: "repair",
        components: {uploadPictureCard},
        data() {
            return {
                tableColumn: tableRepairColumn,
                tableData: [],
                formSearch: {},
                housingEstateList:[],
                value:'',
                dialogVisible: false,
                formPublish: {
                    amount: 0,
                    commentdate: '',
                    comment: '',
                    uuid: '',
                },
                rules:{
                    amount: [{required: true, message: '请输入金额', trigger: 'blur'}],
                    commentdate: [{required: true, message: '请选择处理时间', trigger: 'change'}],
                    comment: [{required: true, message: '请输入处理内容', trigger: 'blur'}]
                },
                thumbnailFinish: []
            };
        },
        mounted() {

        },
        methods: {
            getRepairTableData(params) {
                // return getRepairList(params);
                return [];
            },
            handleSearch() {
                this.$refs['orderTableRef'].getTableData();
            },
            handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            handle(data){
                let {uuid} = data;
                this.formPublish.uuid = uuid;
                this.dialogVisible = true;
            },
            clickSubmit(){
                let that = this;
                // 表单提交
                that.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                        let {uuid,amount,commentdate,comment} = that.formPublish;
                        amount=parseInt((amount * 100).toFixed(0));
                        let thumbnailFinish=this.thumbnailFinish.join(",");
                        /*repairPubRepair({uuid,amount,commentdate,comment,thumbnailFinish}).then(res=>{
                            MessageSuccess('投诉处理成功');
                            that.clickCancel();
                            loading.close();
                        }).catch(err=>{
                            MessageError('投诉处理失败');
                            loading.close();
                        });*/
                    }
                });
            },
            clickCancel(){
                this.$refs["formPublish"].resetFields();
                this.handleSearch();
                this.dialogVisible = false;
            },
            handleSuccess({fileList}) {
                this.thumbnailFinish = fileList.map(item => {
                    let {name, response} = item;
                    let resOk = response && typeof (response) !== undefined;
                    if (resOk) {
                        let {returnObject: {info: {uuid}}} = response;
                        return uuid;
                    } else {
                        return name;
                    }
                });
            },
            handleRemove({fileList}) {
                this.thumbnailFinish = fileList.map(item => {
                    let {name, response} = item;
                    let resOk = response && typeof (response) !== undefined;
                    if (resOk) {
                        let {returnObject: {info: {uuid}}} = response;
                        return uuid;
                    } else {
                        return name;
                    }
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
    .repair {
        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: flex-start;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>
