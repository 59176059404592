<template>
    <section class="home">
        <div class="home-header flex justify-between">
            <div class="tools flex">
                <div :class="['btn', { 'active-btn': activeBtn === item.name }]" v-for="item in timeButtons"
                     :key="item.name" @click="handleClickTools(item)">
                    {{ item.label }}
                </div>
                <div class="date">
                    <el-date-picker v-model="time" type="daterange" size="small" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期"/>
                    <el-button type="primary" size="small" @click="handleClickTime">搜索</el-button>
                </div>
            </div>
            <!--<div class="home-tabs">
                <el-badge class="item" v-for="item in homeTabOptions" :key="item.name" :value="item.value"
                          :hidden="!item.value && item.name==='overview'" :max="99">
                    <div :class="['tab', { 'active-tab': activeTab === item.name }]" @click="clickTab(item)">
                        {{ item.label }}
                    </div>
                </el-badge>
            </div>-->
        </div>
        <div class="container-content hidden-scrollbar">
            <component :is="activeTab" :collection-data="collectionData"/>
        </div>
    </section>
</template>

<script>
    import {timeButtons, homeTabOptions} from "@/views/home/data";
    import {overview, checkOut, complaint, repair, exchangeHouses} from "@/views/home/components";
    import { timeFormat } from "@custom/index";
    //import { getFeedbackList, getRepairList, homeCount } from "@/api/home";
    export default {
        name: "index",
        data() {
            return {
                timeButtons,
                homeTabOptions:homeTabOptions,
                activeTab: "overview",
                activeBtn: "day",
                time: [],
                collectionData:{}
            };
        },
        components: {overview, checkOut, complaint, repair, exchangeHouses},
        methods: {
            handleClickTools({name}) {
                this.activeBtn = name;
                let startDate = "", endDate = "";
                if (name === "day") {
                    startDate = timeFormat(new Date());
                    endDate = timeFormat(new Date());

                }
                if(name==="month"){
                    let now = new Date(); //当前日期
                    let nowMonth = now.getMonth(); //当前月
                    let nowYear = now.getFullYear(); //当前年
                    //本月的开始时间
                    let monthStartDate = new Date(nowYear, nowMonth, 1);
                    //本月的结束时间
                    let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
                    startDate = timeFormat(new Date(monthStartDate));
                    endDate = timeFormat(new Date(monthEndDate));
                }
                this.time = [startDate, endDate];
                this.collectionData={startDate,endDate};
            },
            handleClickTime() {
                if (this.time !== null && this.time !== "") {
                    let [startDate, endDate] = this.time;
                    startDate = timeFormat(new Date(startDate));
                    endDate = timeFormat(new Date(endDate));
                    this.collectionData = {startDate, endDate};
                }else {
                    let startDate = "", endDate = "";
                    let name = this.activeBtn;
                    if (name === "day") {
                        startDate = timeFormat(new Date());
                        endDate = timeFormat(new Date());
                    }
                    if (name === "month") {
                        let now = new Date(); //当前日期
                        let nowMonth = now.getMonth(); //当前月
                        let nowYear = now.getFullYear(); //当前年
                        //本月的开始时间
                        let monthStartDate = new Date(nowYear, nowMonth, 1);
                        //本月的结束时间
                        let monthEndDate = new Date(nowYear, nowMonth + 1, 0);
                        startDate = timeFormat(new Date(monthStartDate));
                        endDate = timeFormat(new Date(monthEndDate));
                    }
                    this.collectionData = {startDate, endDate};
                }
            },
            getFeedbackList(){
                /*getFeedbackList({state:600401}).then(res=>{
                    let {page: {totalNum}} = res;
                    for (let item of this.homeTabOptions) {
                        let {label} = item;
                        if (label === '投诉') item.value = totalNum;
                    }
                });*/
            },
            getRepairList(){
                /*getRepairList().then(res => {
                    let {page: {totalNum}} = res;
                    for (let item of this.homeTabOptions) {
                        let {label} = item;
                        if (label === '维修') item.value = totalNum;
                    }
                });*/
            },
            homeCount(){
                /*homeCount().then(res=>{
                    let {info:{subscribe,change,exit}}=res;
                    for (let item of this.homeTabOptions) {
                        let {label} = item;
                        if (label === '预约') item.value = subscribe;
                        if (label === '换房') item.value = change;
                        if (label === '退房') item.value = exit;
                    }
                });*/
            },
            clickTab(data){
                if(data.name==='appointment'){
                    this.$router.push({name:'booking-management'});
                    return;
                }
                this.activeTab = data.name
            }
        },
        mounted() {
            let startDate = "", endDate = "";
            startDate = timeFormat(new Date());
            endDate = timeFormat(new Date());
            this.collectionData = {startDate, endDate};
            this.time = [startDate, endDate];
            this.getFeedbackList();
            this.getRepairList();
            this.homeCount();
        }
    };
</script>

<style lang="scss" scoped>
    .home {

        .home-header {
            width: 75%;
            position: fixed;
            top: VH(40px);

            .tools {
                margin-left: VW(10px);

                .btn {
                    font-size: rem(12px);
                    cursor: pointer;
                    padding: VH(7px) VW(15px);
                    margin-left: VW(10px);

                    &.active-btn {
                        color: #fff;
                        border-radius: 4px;
                        background: linear-gradient(80deg, #A8D4FF 0%, #2386F6 100%);
                        box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);
                    }
                }

                .el-date-editor {
                    margin: 0 VW(10px);
                }
            }

            .tab {
                cursor: pointer;
                padding: VH(7px) VW(15px);

                &.active-tab {
                    color: #fff;
                    border-radius: 4px;
                    background: linear-gradient(80deg, #A8D4FF 0%, #2386F6 100%);
                    box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);
                }
            }
        }

        .item {
            margin: 0 15px;
        }
    }
</style>
