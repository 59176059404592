<template>
    <section class="complaint padding_10_15">
        <div class="top-bar bg-white">
            <el-input v-model="formSearch.feedSn" size="small" placeholder="请输入投诉编号" style="margin: 0 9px; width: 250px"/>
            <el-input v-model="formSearch.leasor" size="small" placeholder="请输入投诉人" style="margin: 0 9px; width: 250px"/>
            <el-date-picker v-model="formSearch.feedDate" type="date" size="small"
                            value-format="yyyy-MM-dd" format="yyyy-MM-dd" placeholder="选择投诉日期"/>
            <el-select v-model="formSearch.feedType" placeholder="请选择投诉类型" size="small" style="margin:0 10px">
                <el-option label="功能使用" value="700301"/>
                <el-option label="优化建议" value="700302"/>
                <el-option label="新增功能" value="700303"/>
                <el-option label="其他问题" value="700304"/>
            </el-select>
            <el-select v-model="formSearch.state" placeholder="请选择跟进状态" size="small" style="margin-right: 10px">
                <el-option label="未处理" :value="600401"/>
                <el-option label="已处理" :value="600402"/>
            </el-select>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button  @click="handleReset" size="small">重置</el-button>
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getFeedbackTableData" :columns="tableColumn"
                   :query="formSearch" tooltip-effect="light" :height="800">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" v-if="row.stateCode === 600401" @click="handle(row)">处理</span>
                        <span class="table-btn" v-if="row.stateCode === 600402"
                              @click="toExamineShowTrue(row)">审核</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <r-e-dialog title="投诉处理" :visible.sync="dialogVisible" show-footer top="10vh" width="600px"
                    @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
            <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
                <div class="flex">
                    <el-form-item label="处理内容" prop="feedContent">
                        <el-input type="textarea" :rows="2" v-model="formPublish.feedContent" style="width: 350px;"/>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>

        <r-e-dialog title="处理审核" :visible.sync="toExamineShow" show-footer top="10vh" width="600px"
                    @click-submit="toExamineSubmit" @click-cancel="toExamineCancel" @close="toExamineCancel">
            <el-form ref="formPublish1" label-width="110px" size="small" :model="formPublish" :rules="rules">
                <div class="flex">
                    <el-form-item label="处理内容" prop="feedContent">
                        <el-input type="textarea" :rows="2" v-model="formPublish.feedContent" style="width: 350px;"
                                  readonly/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="审核状态" prop="authState">
                        <el-radio-group v-model="formPublish.authState">
                            <el-radio-button label="1">通过</el-radio-button>
                            <el-radio-button label="2">驳回</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <div class="flex" v-if="formPublish.authState==='2'">
                    <el-form-item label="驳回原因" prop="authFeedContent">
                        <el-input type="textarea" :rows="2" v-model="formPublish.authFeedContent" style="width: 350px;"/>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>
    </section>
</template>

<script>
    import { tableComplaintColumn } from "@/views/home/data";
    export default {
        name: "complaint",
        data() {
            return {
                tableColumn: tableComplaintColumn,
                tableData: [],
                formSearch: {
                    feedSn: '',
                    leasor: '',
                    feedDate: '',
                    feedType: '',
                    state: ''
                },
                dialogVisible: false,
                formPublish: {
                    feedContent: null,
                    uuid: null,
                    authFeedContent: null,
                    authState: null,
                },
                rules: {
                    feedContent: [{required: true, message: '请输入处理内容', trigger: 'blur'}],
                    authFeedContent: [{required: true, message: '请输入驳回原因', trigger: 'blur'}],
                    authState: [{required: true, message: '请选择审核状态', trigger: 'change'}],
                },
                toExamineShow: false,
            };
        },
        mounted() {

        },
        methods: {
            getFeedbackTableData(params) {
                return [];
                // return getFeedbackList(params);
            },
            handleSearch() {
                this.$refs['orderTableRef'].getTableData();
            },
            handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            handle(data){
                const {uuid} = data;
                this.formPublish.uuid = uuid;
                this.dialogVisible = true;
            },
            clickSubmit() {
                let that = this;
                // 表单提交
                that.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                        const {uuid, feedContent} = that.formPublish;
                        /*feedbackDealFeed({uuid, feedContent}).then(res => {
                            MessageSuccess('投诉处理申请成功');
                            that.clickCancel();
                        }).finally(() => loading.close());*/
                    }
                });
            },
            clickCancel() {
                this.$refs["formPublish"].resetFields();
                this.handleSearch();
                this.dialogVisible = false;
            },
            toExamineShowTrue(data){
                this.formPublish = data;
                this.toExamineShow = true;
            },
            toExamineSubmit() {
                let that = this;
                that.$refs["formPublish1"].validate((valid) => {
                    if (valid) {
                        const loading = this.$loading({lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'});
                        const {uuid, authFeedContent, authState} = that.formPublish;
                       /* authFeed({uuid, authFeedContent, authState}).then(res => {
                            MessageSuccess('投诉处理成功');
                            that.toExamineCancel();
                        }).finally(() => loading.close());*/
                    }
                });
            },
            toExamineCancel() {
                this.formPublish = {feedContent: null, uuid: null, authFeedContent: null, authState: null};
                this.$refs["formPublish1"].resetFields();
                this.handleSearch();
                this.toExamineShow = false;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .complaint {
        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: flex-start;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>
